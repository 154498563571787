// Core
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from 'components/commonComponents/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import PropTypes from 'prop-types';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import Logo from 'assets/images/battuta_logo.png'

import readexProFont from 'assets/fonts/ReadexPro.ttf'

import TajawalRegular from 'assets/fonts/Tajawal-Regular.ttf'

import "jspdf-autotable";

// Styles
import './styles.scss';

const DownloadPDF = (props) => {
    const { onClose, open, trip, articles } = props;
    const { t } = useTranslation();
    const pdfRef = useRef();
    const [loading, setLoading] = useState(false);

    const generatePDF = () => {
        const input = pdfRef.current;
        html2canvas(input, {
            scale: 1,
            useCORS: true,
            allowTaint: true,
            logging: false,
            padding: 20,
        }).then((canvas) => {
            const imgData = canvas.toDataURL("image/png");
            const pdf = new jsPDF("p", "mm", "a4");
            const pdfWidth = pdf.internal.pageSize.getWidth();
            const pdfHeight = (canvas.height * pdfWidth) / canvas.width;
            let heightLeft = pdfHeight;
            let position = 0;

            pdf.addImage(imgData, "PNG", 0, position, pdfWidth, pdfHeight);
            heightLeft -= pdf.internal.pageSize.getHeight();

            while (heightLeft >= 0) {
                position = heightLeft - pdfHeight;
                pdf.addPage();
                pdf.addImage(imgData, "PNG", 0, position, pdfWidth, pdfHeight);
                heightLeft -= pdf.internal.pageSize.getHeight();
            }
            pdf.save("trip-details.pdf");
            setLoading(false);
        }).catch(() => {
            setLoading(false);
        });
    };

    // const generatePDF2 = () => {
    //     setLoading(true); // Set loading to true before starting PDF generation

    //     setTimeout(() => {
    //         const pdf = new jsPDF("p", "mm", "a4");
    //         const pdfWidth = pdf.internal.pageSize.getWidth();
    //         const pdfHeight = pdf.internal.pageSize.getHeight();
    //         const margin = 10;
    //         const imageHeight = 80;
    //         const headerHeight = 25; // Height for the logo
    //         let y = headerHeight + 10; // Adjusted for header

    //         const logo = Logo // Replace with your logo base64 or URL

    //         // Function to detect Arabic text
    //         const isArabic = (text) => /[\u0600-\u06FF]/.test(text);

    //         // Function to set text alignment based on language
    //         const addText = (text, x, y, size = 12) => {
    //             pdf.setFontSize(size);
    //             const align = isArabic(text) ? "right" : "left";
    //             const direction = isArabic(text) ? "rtl" : "ltr";
    //             pdf.text(text, align === "right" ? pdfWidth - margin : margin, y, { align, direction });
    //         };

    //         // Function to add header with logo
    //         const addHeader = () => {
    //             pdf.setFillColor(7, 128, 189); // Blue color
    //             pdf.rect(0, 0, pdfWidth, headerHeight, "F"); // Background color for header
    //             pdf.addImage(logo, "PNG", margin, 5, 52, 15); // Adjust position and size as needed
    //         };

    //         pdf.addFont(TajawalRegular, "Tajawal-Regular", "normal");
    //         pdf.setFont("Tajawal-Regular");
    //         pdf.setLineHeightFactor(1.5);

    //         // **First Page Header**
    //         addHeader();

    //         // **Trip Name**
    //         addText(trip.tripName, margin, y, 18);
    //         y += 10;

    //         // **Location**
    //         if (trip.location) {
    //             if (trip.location.latitude && trip.location.longitude) {
    //                 const googleMapsLink = `https://www.google.com/maps?q=${trip.location.latitude},${trip.location.longitude}`;
    //                 addText("Location", margin, y);
    //                 y += 6;
    //                 pdf.setTextColor(0, 0, 255); // Blue color for link
    //                 pdf.textWithLink(googleMapsLink, margin, y, { url: googleMapsLink });
    //                 pdf.setTextColor(0, 0, 0); // Reset to black color
    //                 y += 10;
    //             }
    //             y += 10;
    //         }
    //         y += 10;

    //         // **Cover Image**
    //         if (trip.coverImage) {
    //             if (y + imageHeight > pdfHeight - margin) {
    //                 pdf.addPage();
    //                 addHeader();
    //                 y = headerHeight + margin;
    //             }
    //             pdf.setFillColor(255, 255, 255);
    //             pdf.roundedRect(margin, y, pdfWidth - 2 * margin, imageHeight, 10, 10, "F");
    //             pdf.addImage(trip.coverImage, "JPEG", margin, y, pdfWidth - 2 * margin, imageHeight);
    //             y += imageHeight + 10;
    //         }

    //         // **Description**
    //         const descText = pdf.splitTextToSize(trip.description, pdfWidth - 2 * margin);
    //         descText.forEach((line) => {
    //             if (y + 8 > pdfHeight - margin) {
    //                 pdf.addPage();
    //                 addHeader();
    //                 y = headerHeight + margin;
    //             }
    //             addText(line, margin, y);
    //             y += 8;
    //         });

    //         // **URLs**
    //         if (trip.urls.length > 0) {
    //             y += 10;
    //             addText("Links", margin, y, 14);
    //             y += 6;

    //             pdf.setFontSize(10);
    //             trip.urls.forEach((url) => {
    //                 if (y + 6 > pdfHeight - margin) {
    //                     pdf.addPage();
    //                     addHeader();
    //                     y = headerHeight + margin;
    //                 }
    //                 pdf.setTextColor(0, 0, 255); // Blue color for link
    //                 pdf.textWithLink(url, margin, y, { url });
    //                 y += 6;
    //             });
    //             pdf.setTextColor(0, 0, 0); // Reset to black color
    //         }

    //         // **Loop through Articles**
    //         articles.forEach((article) => {
    //             pdf.addPage();
    //             addHeader();
    //             y = headerHeight + margin;

    //             addText(article.articleName, margin, y, 16);
    //             y += 10;

    //             // **Location**
    //             if (article.location) {
    //                 addText(`Location: ${article?.location?.location}`, margin, y);
    //                 y += 10;
    //             }

    //             // **Cover Image**
    //             if (article.coverImage) {
    //                 if (y + imageHeight > pdfHeight - margin) {
    //                     pdf.addPage();
    //                     addHeader();
    //                     y = headerHeight + margin;
    //                 }
    //                 pdf.setFillColor(255, 255, 255);
    //                 pdf.roundedRect(margin, y, pdfWidth - 2 * margin, imageHeight, 10, 10, "F");
    //                 pdf.addImage(article.coverImage, "JPEG", margin, y, pdfWidth - 2 * margin, imageHeight);
    //                 y += imageHeight + 10;
    //             }

    //             // **Article Description**
    //             const articleText = pdf.splitTextToSize(article.description.replace(/<[^>]+>/g, ''), pdfWidth - 2 * margin);
    //             articleText.forEach((line) => {
    //                 if (y + 8 > pdfHeight - margin) {
    //                     pdf.addPage();
    //                     addHeader();
    //                     y = headerHeight + margin;
    //                 }
    //                 addText(line, margin, y, 11);
    //                 y += 8;
    //             });

    //             // **Article URLs**
    //             if (article.urls && article.urls.length > 0) {
    //                 y += 10;
    //                 addText("Links", margin, y, 14);
    //                 y += 6;
    //                 pdf.setFontSize(10);
    //                 article.urls.forEach((url) => {
    //                     if (y + 6 > pdfHeight - margin) {
    //                         pdf.addPage();
    //                         addHeader();
    //                         y = headerHeight + margin;
    //                     }
    //                     pdf.setTextColor(0, 0, 255); // Blue color for link
    //                     pdf.textWithLink(url, margin, y, { url });
    //                     y += 6;
    //                 });
    //                 pdf.setTextColor(0, 0, 0); // Reset to black color
    //             }
    //         });

    //         pdf.save("trip-details.pdf");

    //         // Delay setting loading to false to allow UI update
    //         setTimeout(() => setLoading(false), 500);
    //     }, 100);
    // };

    const generatePDF2 = () => {
        setLoading(true); // Set loading to true before starting PDF generation
    
        setTimeout(() => {
            const pdf = new jsPDF("p", "mm", "a4");
            const pdfWidth = pdf.internal.pageSize.getWidth();
            const pdfHeight = pdf.internal.pageSize.getHeight();
            const margin = 10;
            const imageHeight = 80;
            const headerHeight = 25; // Height for the logo
            let y = headerHeight + 10; // Adjusted for header
    
            const logo = Logo; // Replace with your logo base64 or URL
    
            // Function to detect Arabic text
            const isArabic = (text) => /[\u0600-\u06FF]/.test(text);
    
            // Function to set text alignment based on language
            const addText = (text, x, y, size = 12) => {
                pdf.setFontSize(size);
                const align = isArabic(text) ? "right" : "left";
                const direction = isArabic(text) ? "rtl" : "ltr";
                pdf.text(text, align === "right" ? pdfWidth - margin : margin, y, { align, direction });
            };
    
            // Function to add header with logo
            const addHeader = () => {
                pdf.setFillColor(7, 128, 189); // Blue color
                pdf.rect(0, 0, pdfWidth, headerHeight, "F"); // Background color for header
                pdf.addImage(logo, "PNG", margin, 5, 52, 15); // Adjust position and size as needed
            };
    
            pdf.addFont(TajawalRegular, "Tajawal-Regular", "normal");
            pdf.setFont("Tajawal-Regular");
            pdf.setLineHeightFactor(1.5);
    
            // **First Page Header**
            addHeader();
    
            // **Trip Name**
            addText(trip.tripName, margin, y, 18);
            y += 10;
    
            // **Cover Image**
            if (trip.coverImage) {
                if (y + imageHeight > pdfHeight - margin) {
                    pdf.addPage();
                    addHeader();
                    y = headerHeight + margin;
                }
                pdf.setFillColor(255, 255, 255);
                pdf.roundedRect(margin, y, pdfWidth - 2 * margin, imageHeight, 10, 10, "F");
                pdf.addImage(trip.coverImage, "JPEG", margin, y, pdfWidth - 2 * margin, imageHeight);
                y += imageHeight + 10;
            }
    
            // **Description**
            const descText = pdf.splitTextToSize(trip.description, pdfWidth - 2 * margin);
            descText.forEach((line) => {
                if (y + 8 > pdfHeight - margin) {
                    pdf.addPage();
                    addHeader();
                    y = headerHeight + margin;
                }
                addText(line, margin, y);
                y += 8;
            });
    
            // **Location**
            if (trip.location && trip.location.latitude && trip.location.longitude) {
                const googleMapsLink = `https://www.google.com/maps?q=${trip.location.latitude},${trip.location.longitude}`;
                y += 10;
                addText("Location", margin, y);
                y += 6;
                pdf.setTextColor(0, 0, 255); // Blue color for link
                pdf.textWithLink(googleMapsLink, margin, y, { url: googleMapsLink });
                pdf.setTextColor(0, 0, 0); // Reset to black color
                y += 10;
            }
    
            // **Links**
            if (trip.urls.length > 0) {
                y += 10;
                addText("Links", margin, y, 14);
                y += 6;
                pdf.setFontSize(10);
                trip.urls.forEach((url) => {
                    if (y + 6 > pdfHeight - margin) {
                        pdf.addPage();
                        addHeader();
                        y = headerHeight + margin;
                    }
                    pdf.setTextColor(0, 0, 255); // Blue color for link
                    pdf.textWithLink(url, margin, y, { url });
                    y += 6;
                });
                pdf.setTextColor(0, 0, 0); // Reset to black color
            }
    
            // **Loop through Articles**
            articles.forEach((article) => {
                pdf.addPage();
                addHeader();
                y = headerHeight + margin;
    
                addText(article.articleName, margin, y, 16);
                y += 10;
    
                // **Cover Image**
                if (article.coverImage) {
                    if (y + imageHeight > pdfHeight - margin) {
                        pdf.addPage();
                        addHeader();
                        y = headerHeight + margin;
                    }
                    pdf.setFillColor(255, 255, 255);
                    pdf.roundedRect(margin, y, pdfWidth - 2 * margin, imageHeight, 10, 10, "F");
                    pdf.addImage(article.coverImage, "JPEG", margin, y, pdfWidth - 2 * margin, imageHeight);
                    y += imageHeight + 10;
                }
    
                // **Article Description**
                const articleText = pdf.splitTextToSize(article.description.replace(/<[^>]+>/g, ''), pdfWidth - 2 * margin);
                articleText.forEach((line) => {
                    if (y + 8 > pdfHeight - margin) {
                        pdf.addPage();
                        addHeader();
                        y = headerHeight + margin;
                    }
                    addText(line, margin, y, 11);
                    y += 8;
                });
    
                // **Location**
                if (article.location && article.location.latitude && article.location.longitude) {
                    const googleMapsLink = `https://www.google.com/maps?q=${article.location.latitude},${article.location.longitude}`;
                    y += 10;
                    addText("Location", margin, y);
                    y += 6;
                    pdf.setTextColor(0, 0, 255); // Blue color for link
                    pdf.textWithLink(googleMapsLink, margin, y, { url: googleMapsLink });
                    pdf.setTextColor(0, 0, 0); // Reset to black color
                    y += 10;
                }
    
                // **Article URLs**
                if (article.urls && article.urls.length > 0) {
                    y += 10;
                    addText("Links", margin, y, 14);
                    y += 6;
                    pdf.setFontSize(10);
                    article.urls.forEach((url) => {
                        if (y + 6 > pdfHeight - margin) {
                            pdf.addPage();
                            addHeader();
                            y = headerHeight + margin;
                        }
                        pdf.setTextColor(0, 0, 255); // Blue color for link
                        pdf.textWithLink(url, margin, y, { url });
                        y += 6;
                    });
                    pdf.setTextColor(0, 0, 0); // Reset to black color
                }
            });
    
            pdf.save(`${trip.tripName.trim()}.pdf`);
    
            // Delay setting loading to false to allow UI update
            setTimeout(() => setLoading(false), 500);
        }, 100);
    };
    
    const handleClose = (event, reason) => {
        if (reason === "backdropClick") {
            console.log(reason);
        } else {
            onClose();
            setLoading(false);

        }
    };

    return (
        <>
            <Dialog
                open={open}
                onClose={handleClose}
                className='__download_pdf'
                maxWidth={'md'}
                fullWidth={true}
                disableEscapeKeyDown
            >
                <DialogTitle id="alert-dialog-title">
                    {trip.tripName}
                </DialogTitle>
                <DialogContent>
                    <div className='___trip_pdf' ref={pdfRef}>
                        <h2>{trip.tripName}</h2>
                        {trip.coverImage && (
                            <img src={trip.coverImage} alt="Trip Cover" width="100" />
                        )}
                        <p>{trip.description}</p>

                        <h3 style={{ marginTop: '30px' }}>Articles</h3>
                        {articles.map((article) => (
                            <div className='__article__pdf' key={article.articleId} style={{ marginBottom: "20px" }}>
                                <h4>{article.articleName}</h4>
                                {article.coverImage && (
                                    <img src={article.coverImage} alt={article.articleName} width="100" />
                                )}
                                <p dangerouslySetInnerHTML={{ __html: article.description }} />
                                <p>Likes: {article.totalLikes}</p>
                                <p>Published: {article.displayDate ? article.displayDate : "Date unavailable"}</p>
                            </div>
                        ))}
                    </div>
                </DialogContent>
                <DialogActions>
                    <div className='d-flex align-items-center' style={{ margin: '15px', gap: '10px' }}>
                        <Button
                            label={t('cancel')}
                            handleOnClick={() => handleClose(false)}
                        />
                        {/* <Button
                            label={loading ? t('Downloading...') : t('Download')}
                            loading={loading}
                            handleOnClick={() => {
                                setLoading(true);
                                generatePDF();
                            }}
                            disabled={loading}
                            withLoader
                        /> */}
                        <Button
                            label={loading ? t('Downloading...') : t('Download')}
                            withLoader
                            loading={loading}
                            handleOnClick={() => {
                                setLoading(true);
                                generatePDF2();
                            }}
                            disabled={loading}
                        />
                    </div>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default DownloadPDF;

DownloadPDF.propTypes = {
    onClose: PropTypes.func,
    open: PropTypes.bool,
    trip: PropTypes.object,
    articles: PropTypes.array,
};
